import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, CircularProgress, Grid } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import config from '../../config/config.json';

const PickStatusDistribution = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const selectionDate = formatDate(selectedDate);
        const response = await fetch(
          `http://${config.serverUrl}:${config.servicePort}/report/sumOfPicks`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if (Array.isArray(responseData.results)) {
          const transformedData = responseData.results.map((item, index) => ({
            id: item.status, // `id` is used as the key for each segment
            value: Number(item.total_qty), // `value` holds the numeric value for the pie chart
            label: item.status,
            color: getColor(index), // `label` is used to display the label for each segment
          }));

          setData(transformedData);
          setLoading(false);
        } else {
          setData([]);
          setLoading(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the fetchData function when component mounts
  }, []);
  const getColor = index => {
    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return colors[index % colors.length];
  };
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%' }}>
      <Typography variant="h6" gutterBottom align="center">
        Pick Status Distribution
      </Typography>
      <PieChart
        margin={{ top: 100, bottom: 40, left: 100, right: 100 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'middle' },
            padding: 10,
          },
        }}
        series={[
          {
            data, // Data for the pie chart
            outerRadius: 120,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: 'gray',
            },
            colorByPoint: true,
          },
        ]}
        height={400}
      />
    </Paper>
  );
};

export default PickStatusDistribution;
