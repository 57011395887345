import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import PicksPerHour from '../Graphs/PicksPerHour';
import PickStatusDistribution from '../Graphs/PickStatusDistribution';
import OrderCompletionDistribution from '../Graphs/OrderCompletionDistribution';
const Reports = () => {
  return (
    <Box>
      <Typography variant="h4" component="h2" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={2} width={1400}>
        <Grid item xs={8}>
          <PicksPerHour />
        </Grid>
        <Grid
          item
          xs={4}
          container
          justifyContent="center" // Horizontally center the content
          alignItems="center"
        >
          <PickStatusDistribution />
        </Grid>
        <Grid item xs={12}>
          <OrderCompletionDistribution />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;
