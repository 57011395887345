import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Stack } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import { formatDate } from '../../util/DateParser';
import { downloadToCSV } from '../../util/DataToCSV';
import DatePicker from 'react-datepicker';
import config from '../../config/config.json';
const PicksPerHour = () => {
  const [picksPerHour, setPicksPerHour] = useState([]);
  const [xAxis, setXaxis] = useState([]);
  const [s1Data, setS1] = useState([]);
  const [s3Data, setS3] = useState([]);
  const [s5Data, setS5] = useState([]);
  const [sumData, setSumData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const selectionDate = formatDate(selectedDate);
        const response = await fetch(
          `http://${config.serverUrl}:${config.servicePort}/report/dailyStationPicksPerHour`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if (Array.isArray(responseData.results)) {
          const uniqueStartHours = [
            ...new Set(responseData.results.map(item => item.Start_Hour)),
          ];
          uniqueStartHours.sort((a, b) => {
            const hourA = parseInt(a.split(':')[0], 10);
            const hourB = parseInt(b.split(':')[0], 10);
            return hourA - hourB;
          });
          // Initialize arrays for each PortID with zeros
          const port1Picks = Array(uniqueStartHours.length).fill(0);
          const port3Picks = Array(uniqueStartHours.length).fill(0);
          const port5Picks = Array(uniqueStartHours.length).fill(0);
          const sumByHour = Array(uniqueStartHours.length).fill(0);
          responseData.results.forEach(item => {
            const index = uniqueStartHours.indexOf(item.Start_Hour);
            if (item.PortID === '1') {
              port1Picks[index] = parseInt(item.Picks, 10);
            } else if (item.PortID === '3') {
              port3Picks[index] = parseInt(item.Picks, 10);
            } else if (item.PortID === '5') {
              port5Picks[index] = parseInt(item.Picks, 10);
            }
            sumByHour[index] += parseInt(item.Picks, 10);
          });

          setS1(port1Picks);
          setS3(port3Picks);
          setS5(port5Picks);
          setSumData(sumByHour);
          setXaxis(uniqueStartHours);
          setPicksPerHour(responseData.results);
          setIsDisabled(false);
        } else {
          setPicksPerHour([]);
          setIsDisabled(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the fetchData function when component mounts
  }, []);

  const handleDownloadCSV = () => {
    const dateString = createDateString();
    const fileName = `PPH_Report_${dateString}`;
    downloadToCSV(picksPerHour, fileName);
  };
  const createDateString = () => {
    let dateString = '';
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    if (selectedDate < todaysDate) {
      dateString = formatDate(selectedDate) + '-' + formatDate(todaysDate);
    } else {
      dateString = formatDate(todaysDate);
    }
    return dateString;
  };
  return (
    <Paper>
      {picksPerHour.length > 0 ? (
        <Box align={'center'} sx={{ height: '600px', width: '90%' }}>
          <Typography variant="h6">
            {createDateString()} Picks Per Hour
          </Typography>
          <LineChart
            sx={{ padding: '5px' }}
            label={`Picks Per Hour `}
            series={[
              { data: sumData, label: 'Total', color: '#e15759' },
              { data: s1Data, label: 's1', color: '#f28e2c' },
              { data: s3Data, label: 's3', color: '#4e79a7' },
              { data: s5Data, label: 's5', color: '#76b7b2' },
            ]}
            yAxis={[{ label: 'Picks' }]}
            xAxis={[
              { scaleType: 'point', data: xAxis, label: 'Date and Hour' },
            ]}
          ></LineChart>
        </Box>
      ) : (
        <>
          <p style={{ color: 'red' }}>Error Fetching Picks Per hour</p>
        </>
      )}
    </Paper>
  );
};

export default PicksPerHour;
