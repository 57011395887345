import React, { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Paper,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import config from '../../config/config.json';

const OrderCompletionDistribution = () => {
  const [data, setData] = useState([]);
  const [pickingOrders, setPickingOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const filterPickingOrders = orders => {
    return orders.filter(order => order.status === 'PICKING');
  };
  const countStatusesForPieChart = orders => {
    const statusCount = {};

    // Count the number of orders for each status
    orders.forEach(order => {
      const normalizedStatus = order.status.replace('_', ''); // Normalize status
      if (!statusCount[normalizedStatus]) {
        statusCount[normalizedStatus] = 1;
      } else {
        statusCount[normalizedStatus]++;
      }
    });

    // Convert the status counts to the required format for the pie chart
    return Object.keys(statusCount).map((status, index) => ({
      id: status,
      label: status,
      value: statusCount[status],
      color: getColor(index), // Assign color based on status
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const selectionDate = formatDate(selectedDate);
        const response = await fetch(
          `http://${config.serverUrl}:${config.servicePort}/report/delivery`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if (Array.isArray(responseData.results)) {
          const formattedData = countStatusesForPieChart(responseData.results);
          setData(formattedData);
          const filteredData = filterPickingOrders(responseData.results);
          setPickingOrders(filteredData);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the fetchData function when component mounts
  }, []);
  const getColor = index => {
    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return colors[index % colors.length];
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Stack spacing={2} direction="row">
      <Paper elevation={3} sx={{ padding: 2, width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          Order Completion Distribution
        </Typography>
        <PieChart
          margin={{ top: 100, bottom: 40, left: 100, right: 100 }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              padding: 10,
            },
          }}
          series={[
            {
              data, // Data for the pie chart
              outerRadius: 120,
              highlightScope: { fade: 'global', highlight: 'item' },
              faded: {
                innerRadius: 30,
                additionalRadius: -30,
                color: 'gray',
              },
              colorByPoint: true,
            },
          ]}
          height={400}
        />
      </Paper>
      <Paper elevation={3} sx={{ padding: 2, width: '100%' }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Store</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>Station</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pickingOrders.map(order => (
                <TableRow key={order.orderNumber}>
                  <TableCell>{order.accountNumber}</TableCell>
                  <TableCell>{order.orderNumber}</TableCell>
                  <TableCell>{order.stationGroupid}</TableCell>
                  <TableCell>{order.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};

export default OrderCompletionDistribution;
