import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './components/Pages/Landing';
import Dashboard from './components/Pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Inventory from './components/DashPages/Inventory';
import Reports from './components/DashPages/Reports';
import Deposco from './components/DashPages/Deposco';
import './app.css';
import { AuthProvider } from './components/AuthContext';
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route path="/dashboard/*" element={<ProtectedRoute />}>
              <Route path="*" element={<Dashboard />}>
                <Route path="inventory" element={<Inventory />} />
                <Route path="reports" element={<Reports />} />
                <Route path="deposco" element={<Deposco />} />
              </Route>
              <Route path="*" element={<Landing />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}
export default App;
